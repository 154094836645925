import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { Hero, Intro } from "../../components/StandartPage/index"
import { Services, Steps } from "../../components/Sections/index"
import BlogCard from "../../components/PostCard/PostCardGroup"
import Seo from "../../components/seo"

const FenomenAvukatiPage = ({ data: { blog } }) => (
  <Fragment>
    <Seo
      title="Fenomen Avukatı | Ünlü Avukatı"
      description="Her büyüklükteki fenomenler için uzman hukuki danışmanlık sunuyoruz."
    />
    <Hero
      title="Fenomen Avukatı"
      subtitle="Her büyüklükteki fenomenler için  için uzman hukuki danışmanlık sunuyoruz. Uzman teknolojimiz ve bilişim avukatlarımız, yasal gereksinimlerinize yardımcı olacak zengin bir deneyime sahiptir."
    />
    <Intro
      description="
      Bugün, fenomenlerin mahremiyeti günlük olarak işgal ediliyor. Avukat Bilişim bürosu avukatlarını, ünlüler buna bir son vermek veya en azından özel hayatlarının işgalini sınırlamak istediğinde tutulabilir.
      <br/><br/>
      Fenomenlerin ve ünlülerin mahremiyet hakkı, başkaları tarafından isimlerinin (veya kafa karıştıracak kadar benzer birinin), benzerliklerinin ve görüntülerinin kötüye kullanılması yoluyla sürekli olarak kötüye kullanılıyor.
      <br/><br/>
      Tanıtım hakkı, kimliklerinin ticari kullanımını kontrol etme hakkı sürekli olarak ihlal edilmektedir. Ünlü hastane kayıtları ve diğer özel gerçekler rutin olarak halka sızdırılır. Onların inzivaya veya yalnızlıklarına fotoğrafçılar tarafından izinsiz girilir. Ve fenomenler, yanlış bir ışık altında tutularak sürekli olarak karalanıyor.
      <br/><br/>
      Ünlü Avukat bürosu Bilişim Avukatı, İzmir'de ve uluslararası olarak Türkiye ve Avrupa'da ünlüleri temsil etme deneyimine sahiptir. Hukuk diplomaları ve İzmir ve Türkiye'da 10 yılı aşkın deneyime sahip olan İzmir'lı ünlü avukat bürosu Avukat Bilişim, Türkiye'de gizlilik ve tanıtım haklarınızı korumak için yanınızda olmasını istediğiniz avukattır.
      <br/><br/>
      Onlarca yıldır fenomenlerin fotoğrafları, fenomenlerin izni olmadan reklamlarda kullanılmıştır. Magazin dergilerinde ve gazetelerde yer alan hikayeler, onları rutin olarak hiçbir ilgileri olmayan uygunsuz davranışlarla ilişkilendirir ve asla yapmadıkları eylemlerle suçlanırlar. Gizli yollarla fotoğraf çekilmekte ve cep telefonu görüşmeleri dinlenmektedir.
      <br/><br/>
      Herkes bir anda ünlü olabilir. Ancak bir ünlünün adının, benzerliğinin, sesinin veya görüntüsünün zimmete para geçirme iddiasında bulunabilmesi için, bir ünlünün davalının ünlünün kimliğinin bir yönünü kullandığını, bunun ticari veya istismar amaçlı kullanıldığını ve Avukat Bilişim böyle bir kullanım için asla izin vermedi."
    />
    <Services />
    <Steps />
    <BlogCard posts={blog.edges} />
  </Fragment>
)

export default FenomenAvukatiPage

export const blogQuery = graphql`
  {
    blog: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            date
            slug
            tags
            title
            description
            Image {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
  }
`
